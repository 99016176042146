import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import FigmaEmbed from "../../../components/FigmaEmbed/FigmaEmbed.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Introduction`}</h2>
    <p>{`Building the right landing page is important with the right structure for a great impression. By managing the right impression of your product you will get visitors in to the next funnel. This pattern consists of an explanation for building a structured landing page. This pattern also complies with the Personal Data Protection (UU PDP) in Indonesia, which includes the cookies allowance for better landing page performance.`}</p>
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Landing Page Structure</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Cookies Allowance</AnchorLink>
    </AnchorLinks>
    <div className="divi" />
    <h2>{`Data Protection (UU PDP) Compliance`}</h2>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Important Information:`}</strong>{` This pattern complies with UU PDP principles, ensuring top-notch consent, data protection, and privacy of the users.`}</p>
    </InlineNotification>
    <p>{`In accordance with the Personal Data Protection Act (UU PDP) in Indonesia, our login and registration processes are designed to ensure the highest standards of data security and user privacy. We are committed to complying with all regulations set forth by the UU PDP, ensuring that personal data is handled with the utmost care and confidentiality.`}</p>
    <div className="divi" />
    <h2>{`Landing Page Structure`}</h2>
    <p>{`Landing page structure is usually done by dividing the website into sections, this will help users to focus on the goal of each section, some other reason is to help with the development process, making it easier to adjust the style of the website, test and tweak, and help manage the results.`}</p>
    <h3>{`Header`}</h3>
    <p>{`The header should be the main point to explain the product or the website briefly. The header consists of a clear headline (introduce the problem or solution that you are solving) and gives a clear CTA (that stands out and presents the clear action that we want users to do, whether it is to register or to explore the website).`}</p>
    <p>{`Using Imagery/hero images (static or video) can help provide a vocal point to promote the product or website, it should be directly related to the proposition but it shouldn’t dominate the page. This will help emphasize the importance of the call to action.`}</p>
    <FigmaEmbed figmaSrc="https://www.figma.com/design/Y5JEOWyfVT4Hk1sV2tmtk5/Pattern-Landing-Page?node-id=540-101910" imageSrc="https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/landing-page/guideline/pattern-landing-page-header.png" mdxType="FigmaEmbed" />
    <h3>{`Feature`}</h3>
    <p>{`This section consists of an explanation of the benefit of the product that could be described using the combination of imagery and texts (sometimes even short video). 90% of people usually scan through the website, so using good imagery will help grab their attention and make sure they see the product as the solution that they need.`}</p>
    <p>{`To provide a better explanation of the product, don’t only share about the feature, but provide more explanation of how the product can help solve the potential users’ problems. Focus on benefits that can eliminate their sress, simplify things, and increase their productivity.`}</p>
    <FigmaEmbed figmaSrc="https://www.figma.com/design/Y5JEOWyfVT4Hk1sV2tmtk5/Pattern-Landing-Page?node-id=702-105239" imageSrc="https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/landing-page/guideline/pattern-landing-page-feature.png" mdxType="FigmaEmbed" />
    <h3>{`Testimonials`}</h3>
    <p>{`Social proof is usually built in the form of testimonials, reviews, or trust seals, this is one of the ways to create interest and drive credibility to provide more conversions. Some of the social proof is Download counts (the number of people joining can help increase the interest of useres). Media mentions (lists of news logos where the product is featured, this will increase the trust and legibility of the product), and Testimonials (customers feedback are proven to help improve the conversion, make sure to use photo and official title of the customers to provide better credibility of their testimonies).`}</p>
    <FigmaEmbed figmaSrc="https://www.figma.com/design/Y5JEOWyfVT4Hk1sV2tmtk5/Pattern-Landing-Page?node-id=3777-43495" imageSrc="https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/landing-page/guideline/pattern-landing-page-testimonials.png" mdxType="FigmaEmbed" />
    <h3>{`Final CTA (Call To Action)`}</h3>
    <p>{`This section is important to make sure to grab users’ attention as they are ready to conver to the next step. This section should point out the main actions that we want users to take on the website, such as signing up, purchasing a product, requesting a demo, or registering for the website.`}</p>
    <p>{`CTA copy is essential to be clear on what customers should do, it can be as descriptive as possible (avoid using generic copy such as “Learn more” or “Find more”), emphasize the action such as “Read the ebook now” or “Reverse my copy today”. This copy will help create a more personalized and inviting copy.`}</p>
    <FigmaEmbed figmaSrc="https://www.figma.com/design/Y5JEOWyfVT4Hk1sV2tmtk5/Pattern-Landing-Page?node-id=3777-43496" imageSrc="https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/landing-page/guideline/pattern-landing-page-final-cta.png" mdxType="FigmaEmbed" />
    <div className="divi" />
    <h2>{`Cookies Allowance`}</h2>
    <p>{`When creating a good landing page, we need to collect some users’ data and preferences to make the landing page more desired and match users’ needs. Cookies are one way to store users’ data while they browse the website. Providing the ability for customers to give consent is one of the priorities for their experience with cookies allowance.`}</p>
    <DoDontRow className="legion-pattern-landing-page-row-first" mdxType="DoDontRow">
      <DoDont type="do" aspectRatio="16:9" colLg="6" captionTitle="Do" caption="When providing cookies, it's best to give an option for the users to consent (either agree or reject) to the cookies." mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/landing-page/guideline/pattern-landing-page-cookies-allowance-do-1.png",
          "alt": "Provide option for users to consent on Cookies"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="16:9" colLg="6" captionTitle="Don't" caption="Provide cookies just by using only 1 CTA (only agree), this will make users lose their ability to give consent." mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/landing-page/guideline/pattern-landing-page-cookies-allowance-dont-1.png",
          "alt": "Do Not limit option to only having one CTA to consent on Cookies"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow className="legion-pattern-landing-page-row-second" mdxType="DoDontRow">
      <DoDont type="do" aspectRatio="16:9" colLg="6" captionTitle="Do" caption="Provide cookies allowance with further settings for managing which cookies users want to allow." mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/landing-page/guideline/pattern-landing-page-cookies-allowance-do-2.png",
          "alt": "Provide further settings to manage which Cookies to allow for users"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="16:9" colLg="6" captionTitle="Don't" caption="Provide cookies allowance without further settings. Managing the cookies allowance is an important part of giving consent." mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/landing-page/guideline/pattern-landing-page-cookies-allowance-dont-2.png",
          "alt": "Do Not limit option for users to manage their Cookies settings"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      